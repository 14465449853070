<template>
  <div class="projects-container">
    <h1>项目一览</h1>
    <div class="projects-list">
      <div v-if="projects.length" class="cards-container">
        <div class="project-card" v-for="project in projects" :key="project.id">
          <div class="project-info">
            <img :src="project.logo" alt="Project Logo" class="project-logo" />
            <div class="project-content">
              <h3>{{ project.name }}</h3>
              <p>项目描述: {{ project.description }}</p>
              <p>创建日期: {{ formatCreatedTime(project.creationDate) }}</p>
              <p>仓库地址: <a :href="project.repoUrl" target="_blank" rel="noopener">{{ project.name }}</a></p>
              <p>维护者: {{ project.maintainer }}</p>
              <router-link :to="{ name: 'ProjectDetail', params: { id: project.id }}" class="detail-link">详情</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        正在加载项目列表...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProjectsList',
  data() {
    return {
      projects: []
    };
  },
  methods: {
    formatCreatedTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    fetchProjects() {
      axios.get('api/Project/GetProjects')
        .then(response => {
          this.projects = response.data;
        })
        .catch(error => {
          if (error.response) {
            console.error("Server responded with status:", error.response.status);
            console.error("Response data:", error.response.data);
          } else {
            console.error("Error:", error.message);
          }
        });
    }
  },
  mounted() {
    this.fetchProjects();
  }
};
</script>

<style>
.projects-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
}

.project-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 600px;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2 2 calc(40% - 40px); 
}

.project-card:hover {
  transform: translateY(-10px);
}

.project-logo {
  border-radius: 10px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin-bottom: 10px;
  margin-right: 20px;
}

.project-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.project-content {
  flex: 1;
  text-align: left;
}

.project-content h3 {
  margin: 10px 0 5px;
  font-size: 18px;
}

.project-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.project-content a {
  color: #409EFF;
  text-decoration: none;
}

.project-content a:hover {
  text-decoration: underline;
}

.project-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
}

.detail-link {
  color: #409EFF;
  text-decoration: none;
}

.detail-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .member-card {
    flex: 4 4 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .member-card {
    flex: 1 1 100%;
  }
}
</style>