<template>
  <div class="news-container">
    <h1>新闻动态</h1>
    <div class="news-list">
      <div class="news-card" v-for="news in newsList" :key="news.id">
        <h3>{{ news.title }}</h3>
        <p>{{ news.summary }}</p>
        <p>{{ formatCreatedTime(news.createdTime) }}</p>
        <a :href="news.url" target="_blank" rel="noopener">阅读更多</a>
      </div>
    </div>
    <div v-if="isLoading" class="loading">加载中...</div>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewsView',
  data() {
    return {
      newsList: [],
      isLoading: true,
      error: null,
    };
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    formatCreatedTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    async fetchNews() {
      await axios.get('api/News/GetNewss')
          .then(response => {
            this.newsList = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            console.error("Error fetching projects:", error);
          });
    }
  }
};
</script>

<style>
.news-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.news-card {
  background-color: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.3333% - 20px);
  box-sizing: border-box;
}

.news-card h3 {
  margin: 0 0 10px;
  font-size: 18px;
}

.news-card p {
  color: #555;
}

.news-card a {
  color: #409EFF;
  text-decoration: none;
}

.news-card a:hover {
  text-decoration: underline;
}

.loading {
  text-align: center;
  margin-top: 20px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .news-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .news-card {
    width: 100%;
  }
}
</style>