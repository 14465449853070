<template>
    <div class="members-container">
    <h1>组织成员</h1>
      <div class="members-list">
        <div v-if="members.length" class="cards-container">
          <div class="member-card" v-for="member in members" :key="member.id">
            <img :src="member.avatar" alt="Avatar" class="avatar" />
            <div class="member-info">
              <h3>{{ member.name }}</h3>
              <p>{{ member.level }}</p>
              <p>{{ member.language }}</p>
              <p>{{ member.direction }}</p>
              <div class="member-icons">
                <a v-if="member.giteeUrl && member.giteeUrl !== '#'" :href="member.giteeUrl" target="_blank" rel="noopener">
                    <svg width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg" name="zi_tmGitee" viewBox="0 0 2000 2000"><path d="M898 1992q183 0 344-69.5t283-191.5q122-122 191.5-283t69.5-344q0-183-69.5-344T1525 477q-122-122-283-191.5T898 216q-184 0-345 69.5T270 477Q148 599 78.5 760T9 1104q0 183 69.5 344T270 1731q122 122 283 191.5t345 69.5zm199-400H448q-17 0-30.5-14t-13.5-30V932q0-89 43.5-163.5T565 649q74-45 166-45h616q17 0 30.5 14t13.5 31v111q0 16-13.5 30t-30.5 14H731q-54 0-93.5 39.5T598 937v422q0 17 14 30.5t30 13.5h416q55 0 94.5-39.5t39.5-93.5v-22q0-17-14-30.5t-31-13.5H842q-17 0-30.5-14t-13.5-31v-111q0-16 13.5-30t30.5-14h505q17 0 30.5 14t13.5 30v250q0 121-86.5 207.5T1097 1592z" fill="red"/></svg>
                </a>
                <a v-if="member.githubUrl && member.githubUrl !== '#'" :href="member.githubUrl" target="_blank" rel="noopener">
                    <svg width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg" name="zi_tmGithub" viewBox="0 0 2000 2000"><path d="M602 1631q0 14-19.5 16t-19.5-12.5q0-14.5 18-14.5 11 0 16 3.5t5 7.5zm-112-15q-4 4 1.5 9.5t13 9q7.5 3.5 14.5 0t9-13q2-9.5-16-14.5t-22 9zm160-7q-19 3-17 14t7.5 13q5.5 2 12.5 2 18-7 16.5-18t-19.5-11zM889 218q-250 0-453.5 116.5T116 654Q0 857 0 1104q0 302 170.5 539.5T617 1972q29 7 45.5-7.5T679 1928l-4-221-40 4q-47 7-91 3-61-7-105-33-51-29-69-83l-14-29q-15-33-37-58-25-33-50-47l-19-15q-18-14-16-27t42-13l25 4q29 11 55 25 36 26 58 66 51 90 145 98 58 7 120-22 11-80 58-124-131-14-196-36-102-36-153-112-58-91-58-255 0-69 20-114t64-100q-15-33-15-83-7-80 26-160 32-11 98 14 47 19 105 51l47 33q109-33 227-33t230 33l47-33q59-32 102-51 65-25 102-14 29 80 25 160-4 50-18 87 51 54 69 91 25 50 25 119 0 164-61 258-55 73-156 109-66 22-200 36 62 51 62 167v353q0 22 16 36.5t45 7.5q273-91 441.5-328.5T1800 1104q0-247-123-454-120-199-330.5-315.5T889 218zM352 1471q-7 7 4 18t18 3.5q7-7.5-4-18t-18-3.5zm-40-29q-4 7 9 14.5t16.5-2q3.5-9.5-7.5-14.5t-18 2zm120 127q-4 4-4 11t7 14q15 15 26 4 4-4 2-11t-7.5-12.5q-5.5-5.5-13-7.5t-10.5 2zm-44-51q-7 4 0 22 8 4 13 7.5t9-.5q11-7 0-22-4-7-9-9t-13 2z"/></svg>
                </a>
                <a v-if="member.homeUrl && member.homeUrl !== '#'" :href="member.homeUrl" target="_blank" rel="noopener">
                    <svg  width="1rem" height="1rem" xmlns="http://www.w3.org/2000/svg" name="zi_home" viewBox="0 0 2000 2000"><path d="M1525 1340v447q0 31-22 53t-53 22H349q-31 0-53-22t-22-53v-447q0-19 12-32l588-482q13-9 25.5-9t24.5 9l589 482q12 13 12 32zm263-191l-263-216V373q0-16-11-27t-26-11h-176q-15 0-26 11t-11 27v353L996 495q-43-35-96.5-35T806 495L11 1149q-9 9-11 24.5t8 28.5l78 97q9 12 25 12t28-9l735-604q13-9 25.5-9t24.5 9l736 604q12 9 28 9t25-12l78-97q10-13 8-28.5t-11-24.5z"/></svg>
                </a>
            </div>
            </div>
          </div>
        </div>
        <div v-else>
          加载中...
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'MembersList',
    data() {
      return {
        members: [],
        loadComplete: false
      };
    },
    methods: {
      fetchMembers() {
        axios.get('api/Member/GetMembers')
          .then(response => {
            this.members = response.data;
            this.loadComplete = true;
          })
          .catch(error => {
            console.error("Error fetching members:", error);
            this.loadComplete = true;
          });
      }
    },
    mounted() {
      this.fetchMembers();
    }
  };
  </script>
  
  <style>
  .members-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
 }

  .members-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 30px;
  }
  
  .member-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex: 1 1 calc(33.333% - 40px);
    max-width: 150px;
    transition: transform 0.2s;
  }
  
  .member-card:hover {
    transform: translateY(-10px);
  }
  
  .avatar {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  
  .member-info {
    margin-top: 10px;
  }
  
  .member-info h3 {
    margin: 10px 0 5px;
    font-size: 16px;
  }
  
  .member-info p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .member-card {
      flex: 2 2 calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .member-card {
      flex: 1 1 100%;
    }
  }
  </style>