import { createRouter, createWebHistory } from 'vue-router';
import BaseLayout from '@/components/BaseLayout.vue';
import News from '@/views/NewsView.vue';
import ProjectsList from '@/views/ProjectsListView.vue';
import ProjectDetail from '@/views/ProjectDetailView.vue';
import MembersList from '@/views/MemberView.vue';
import TechnicalCommittee from '@/views/TechnicalCommitteeView.vue';
import AboutView from '@/views/AboutView.vue';

const routes = [
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'home.html',
        component: News,
        meta: { title: '首页 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'project-list.html',
        name: 'project-list',
        component: ProjectsList,
        meta: { title: '项目一览 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: '/project/:id.html',
        name: 'ProjectDetail',
        component: ProjectDetail,
        meta: { title: '项目详情 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'member-list.html',
        name: 'members',
        component: MembersList,
        meta: { title: '成员列表 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'committee-list.html',
        name: 'committee',
        component: TechnicalCommittee,
        meta: { title: '技术委员会 - 易开元(Easy-Open-Meta)' }
      },
      {
        path: 'about.html',
        name: 'about',
        component: AboutView,
        meta: { title: '关于我们 - 易开元(Easy-Open-Meta)' }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '易开元(Easy-Open-Meta)';
  next();
});

export default router;
export { routes };